import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import BaseErrorMessage from '@/components/BaseErrorMessage.vue';
import BaseLinkItem from '@/components/BaseLinkItem.vue';
import BaseTextInput from '@/components/BaseTextInput.vue';
import TheExecuteButton from '@/components/TheExecuteButton.vue';
import TheFramedWindow from '@/components/TheFramedWindow.vue';
import TheJsonViewer from '@/components/TheJsonViewer.vue';
import TheParamsForm from '@/components/TheParamsForm.vue';
import TheSendTokensForm from '@/components/TheSendTokensForm.vue';

Vue.component('base-error-message', BaseErrorMessage);
Vue.component('base-link-item', BaseLinkItem);
Vue.component('base-text-input', BaseTextInput);
Vue.component('the-execute-button', TheExecuteButton);
Vue.component('the-framed-window', TheFramedWindow);
Vue.component('the-json-viewer', TheJsonViewer);
Vue.component('the-params-form', TheParamsForm);
Vue.component('the-send-tokens-form', TheSendTokensForm);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
